import { RouteComponent } from "@remix-run/node";
import {
	isRouteErrorResponse,
	useLoaderData,
	useLocation,
	useRouteError,
} from "@remix-run/react";
// import { ErrorComponent } from "~/components/error";
import { Outlet } from "@remix-run/react";
import { V2_ErrorBoundaryComponent } from "@remix-run/server-runtime/dist/routeModules";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Footer } from "~/components/footer";
import { TopBar } from "~/components/topbar";
import { _PublicLoaderResult } from "./_layout.server";

export { loader, headers } from "./_layout.server";

const _General: RouteComponent = () => {
	const loaderData = useLoaderData<_PublicLoaderResult>();
	const { disableSignIn } = loaderData;

	return (
		<div className="flex flex-col w-full min-h-[100vh] items-center">
			<TopBar disableSignIn={disableSignIn} className="fixed" />
			<div className="flex flex-col w-full h-full min-h-screen overflow-x-hidden max-w-[100vw]">
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export const ErrorBoundary: V2_ErrorBoundaryComponent = () => {
	const error = useRouteError();
	const location = useLocation();
	if (isRouteErrorResponse(error)) {
		useEffect(() => {
			ReactGA.event(
				{
					category: "User",
					action: "exception",
				},
				{
					status: error.status,
					statusText: error.statusText,
					location,
					message: error.data.error,
					description: error.data.error,
					fatal: true,

					error,
				},
			);
		}, []);
		return (
			<div className="flex flex-col w-full min-h-small items-center">
				<TopBar />
				<div className="flex flex-col w-full h-full overflow-x-hidden max-w-[100vw] p-8 justify-center">
					<h1>Oops</h1>
					<p>Something went wrong.</p>
					<p>Status: {error.status}</p>
					<p>{error.data.error}</p>
				</div>
			</div>
		);
	}

	useEffect(() => {
		ReactGA.event("exception", {
			message: (error as any).message || "Unknown Error",
			description: (error as any).message || "Unknown Error",
			location,
			fatal: true,
			error,
		});
	}, []);
	return (
		<div className="flex flex-col w-full min-h-small items-center">
			<TopBar />
			<div className="flex flex-col w-full h-full overflow-x-hidden max-w-[100vw] p-8 justify-center">
				<h1>Oops</h1>
				<p>Something went wrong.</p>
				<pre>{(error as any).message || "Unknown Error"}</pre>
			</div>
		</div>
	);
};

export default _General;
